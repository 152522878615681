<template style="background-color: white">
    <div id="app-student" class="app-add">
      <loading :loading="loading" text="提交中"></loading>
        <el-form ref="form" :model="form" :rules="rules"  label-width="100px">
            <el-form-item label="手机号" prop="username">
                <el-input v-model="form.username" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="昵称" prop="nickname">
                <el-input v-model="form.nickname" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item style="display: none">
                <el-button @click="submit('form')" id="layuiadmin-app-form-submit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {UrlEnum,TipEnum} from "../../../public/js/common-vue";
    import {getLocalProjectId} from "../../../public/js/common-vue";
    import {isPhone} from "../../../public/js/common-vue";
    import Loading from "@/components/Loading";

    export default {
      components: {Loading},
      data() {
            return {
                name: "Add.vue",
                form:{
                    username:'',
                    nickname: '',
                    projectId: getLocalProjectId()
                },
                rules:{
                    username :[{required: true, message: '请输入手机号', trigger: 'blur'}],
                    nickname :[{required: true, message:'请输入昵称', trigger:'blur'}]
                },
                loading: false
            }
        },
        methods: {
            submit: function (formData) {
                this.$refs[formData].validate((valid) => {
                    if (valid) {
                        if(!isPhone(this.form.username)){
                            this.showMsgError("请输入正确的手机号")
                            return;
                        }
                        this.loading = true;
                        this.$http({
                            method: "post",
                            url: UrlEnum.STUDENT,
                            data: this.form,
                        })
                            .then((res) => {
                              this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
                              setTimeout(function () {
                                window.parent.postMessage({
                                  msg: '',
                                  key: 'refresh'
                                }, '*')
                              }, 800);
                            })
                            .catch((res) => {
                                this.loading = false;
                                if(res.response != null){
                                    const data = res.response.data;
                                    this.showMsgError(data.message);
                                }
                            });
                    }
                })
            }
        }
    }
</script>

<style scoped>
    @import "../../../static/css/add.css";
</style>
